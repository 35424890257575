<template>
  <div>
    <!-- <navbar /> -->
    <main class="profile-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://cdf.fizo.app/img/smart/pattern.png');
            background-color: #191b4d;
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-blue"
          ></span>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <section class="relative py-1 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="flex flex-wrap mt-4">
              <div
                class="w-full mb-12 px-4"
                v-for="(item, index) in images"
                :key="index"
              >
                <div
                  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                >
                  <img
                    alt="1"
                    :src="`https://cdf.fizo.app/delete/${item}.png`"
                    class=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>

<script>
import FooterComponent from "@/components/Footers/Footer.vue";
// import CardImage from "@/components/Cards/CardImage.vue";

const images = [7, 1, 2, 3, 4, 5, 6, 8, 9];
export default {
  components: {
    FooterComponent,
    // CardImage,
  },

  data() {
    return {
      images,
    };
  },
};
</script>
